//Defino la funcion para scollear
function scrollTo(elem){
  document.querySelector(elem).scrollIntoView({
    block: "start",
    behavior: "smooth" //solo funciona en firefox pero no me importa
  });
}

//Escucho a los botones que me dirigen a las secciones
function btnGotoEvent(i){
  document.querySelectorAll('[data-goto]')[i].addEventListener('click', (event) =>{
    let target = event.srcElement || event.originalTarget;
    let goto = target.getAttribute("data-goto");
    scrollTo(goto);
    ga('send', 'event', 'navigate', 'navigate', goto);
  });
}



function toggleJob(target){
  target.classList.toggle("active");
}


function jobEvent(i){
  document.querySelectorAll('.job')[i].addEventListener('click', (event) =>{
    let target = event.currentTarget;
    toggleJob(target);
    ga('send', 'event', 'job', 'click', target.firstElementChild.alt);
  });
}

//Validacion del formulario
function cFormValidate(form){
  imprime(form.button, `Enviando...`, 1);

  let name = form.name.value,
    mail = form.email.value,
    msg = form.msg.value;

  clean(document.querySelectorAll('.alert'));

  if(!name){
    let text = `Por favor escribe tu nombre`;
    imprime(form.name, text, 0);
    ga('send', 'event', 'contact', 'send', 'name err');
  }
  if(!mail){
    let text = `Por favor escribe tu e-mail`;
    imprime(form.email, text, 0);
    ga('send', 'event', 'contact', 'send', 'mail err');
  }
  if(!msg){
    let text = `Por favor escribe tu mensaje`;
    imprime(form.msg, text, 0);
    ga('send', 'event', 'contact', 'send', 'msg err');
  }
  if(!name || !mail || !msg){
    return false;
    ga('send', 'event', 'contact', 'send', 'all err');
  }

  let successTxt = `Tu mensaje ha sido enviado. Te responderemos a la brevedad.`;
  let errTxt = `Upss, ha habido un error al enviar el mensaje. Intenta otra vez.`
  //Lo mando
  sendMsg({name: name, mail: mail, msg: msg})
  .then((res) =>{
    if(res.status == 200){
      imprime(form.button, successTxt, 1);
      ga('send', 'event', 'contact', 'send', 'success');
    }else{
      imprime(form.button, errTxt, 0);
      ga('send', 'event', 'contact', 'send', 'srv err');
    }
  })
  .catch((err) =>{
    imprime(form.button, errTxt, 0);
    ga('send', 'event', 'contact', 'send', 'srv err');
  });
}

function sendMsg(Data){
  let data = JSON.stringify(Data);
  let makeSend = new Promise(function(res,rej){
    setTimeout(() => {
      res();
    }, 960); // JAJA

    // fetch('/contact', {
    //   method: 'post',
    //   headers: {
    //     'Accept': 'application/json',
    //     'Content-Type': 'application/json'
    //   },
    //   body: data
    // })
    // .then((response) =>{
    //   res(response);
    // })
    // .catch((err) =>{
    //   rej(err);
    // })
  });
  return makeSend;
}

function clean(elem){
  if(!elem) return;

  for(let i = elem.length - 1; i >= 0; i--){
    elem[i].parentNode.removeChild(elem[i]);
  }
}


function imprime(Elem, text, Type){

  let view = (impIn, model) => {
    impIn.insertAdjacentHTML('afterend', model);
  }

  let template = (txt, type) =>{
    return `<p class="alert ${type}"><span>${txt}</span></p>`;
  }

  switch(Type){
    case 0: //error
      view(Elem, template(text, 'error'));
      break;
    case 1:
      view(Elem, template(text, 'success'));
      break;
  }

}


//Lo que ejecuto cuando todo este cargado
window.addEventListener('load', () =>{
  for (let i = 0; i < document.querySelectorAll('[data-goto]').length; i++){
    btnGotoEvent(i);
  }

  for(let i = 0; i < document.querySelectorAll('.job').length; i++){
    jobEvent(i);
  }

  document.querySelector('#contact-form').addEventListener('submit', (event) =>{
    event.preventDefault();
    let target = event.srcElement || event.originalTarget;
    cFormValidate(target);
  });
});
